<template>
  <div class="customerProfile">
    <en-table-layout :tableData="activeName === '1' ? tableData.data : tableDataTwo.data" :toolbarBtn="false">
      <template slot="header">
        <el-tabs v-model="activeName" type="card" @tab-click="handleClick">
          <el-tab-pane label="客户档案" name="1"></el-tab-pane>
          <el-tab-pane label="客户账期申请" name="2">
            <div style="padding:10px 15px">
              <h3>客户账期申请流程：</h3>
              <div class="headerStep">
                <p class="stepImage">
                  <img src="../../../assets/step/step1.png" alt="">
                  <span>销售人员提交账期申请</span>
                </p>
                <p class="stepLine">
                  <span class="line"></span>
                  <span class="arrow-right"></span>
                </p>
                <p class="stepImage">
                  <img style="width:66px" src="../../../assets/step/step2.png" alt="">
                  <span>部门初审（默认审核通过）</span>
                </p>
                <p class="stepLine">
                  <span class="line"></span>
                  <span class="arrow-right"></span>
                </p>
                <p class="stepImage">
                  <img style="width:92px" src="../../../assets/step/step3.png" alt="">
                  <span>财务复审并修改客户账期信息</span>
                </p>
                <p class="stepLine">
                  <span class="line"></span>
                  <span class="arrow-right"></span>
                </p>
                <p class="stepImage">
                  <img src="../../../assets/step/step4.png" alt="">
                  <span>客户账期申请成功</span>
                </p>
              </div>
            </div>
          </el-tab-pane>
        </el-tabs>
      </template>

      <template slot="toolbar" v-if="activeName == '1'">
        <el-form-item label="更新时间" class="col-auto">
          <el-date-picker style="width: 220px" v-model="order_time_range" type="daterange" align="center" size="medium"
            :editable="false" unlink-panels range-separator="-" start-placeholder="开始日期" end-placeholder="结束日期"
            @change="handleDateChange"></el-date-picker>
        </el-form-item>
        <el-form-item label="状态" class="col-auto">
          <el-select style="width: 100px" v-model="params.status" size="small" placeholder="请选择" clearable>
            <el-option label="全部" value="" />
            <el-option label="启用中" :value="1" />
            <el-option label="已禁用" :value="0" />
          </el-select>
        </el-form-item>
        <el-form-item label="账期类型" class="col-auto">
          <el-select style="width: 100px" v-model="params.account_period" size="small" placeholder="请选择" clearable>
            <el-option label="全部" value="" />
            <el-option label="有账期" :value="1" />
            <el-option label="无账期" :value="0" />
          </el-select>
        </el-form-item>
        <el-form-item class="col-auto">
          <el-input style="width: 300px" v-model.trim="params.keyWords" placeholder="请输入关键字搜索" clearable>
            <el-select style="width: 120px;" v-model="search_type" slot="prepend">
              <el-option label="客户名称" value="name"></el-option>
              <el-option label="客户编号" value="no"></el-option>
              <el-option label="联系人名称" value="contacts_person"></el-option>
              <el-option label="销售人员" value="sell_name"></el-option>
              <el-option label="联系电话" value="contacts_phone"></el-option>
            </el-select>
          </el-input>
        </el-form-item>
        <el-form-item class="col-auto">
          <el-button native-type="submit" @click.stop.prevent="advancedSearchEvent" size="small"
            type="primary">搜索</el-button>
        </el-form-item>
        <el-form-item class="col-auto">
          <el-button @click="addCustom" size="small" type="primary">添加客户</el-button>
          <el-button @click="submitImport" :loading="importLoading" size="small" type="primary">导出全部</el-button>
        </el-form-item>
      </template>
      <template slot="toolbar" v-if="activeName == '2'">
        <el-form-item label="申请时间" class="col-auto">
          <el-date-picker style="width: 220px" v-model="order_time_range" type="daterange" align="center" size="medium"
            :editable="false" unlink-panels range-separator="-" start-placeholder="开始日期" end-placeholder="结束日期"
            @change="handleDateChangeTwo"></el-date-picker>
        </el-form-item>
        <el-form-item label="审核状态" class="col-auto">
          <el-select style="width: 100px" v-model="params.apply_status" size="small" placeholder="请选择" clearable>
            <el-option label="全部" value="" />
            <el-option label="待部门初审" :value="0" />
            <el-option label="待财务复审" :value="1" />
            <el-option label="审核完成" :value="3" />
            <el-option label="审核拒绝" :value="4" />
          </el-select>
        </el-form-item>
        <el-form-item class="col-auto">
          <el-input style="width: 300px" v-model.trim="params.keyWords" placeholder="请输入关键字搜索" clearable>
            <el-select style="width: 120px;" v-model="search_type" slot="prepend">
              <el-option label="客户名称" value="customer_name"></el-option>
              <el-option label="客户编号" value="customer_no"></el-option>
              <el-option label="联系人名称" value="contacts_person"></el-option>
              <el-option label="销售人员" value="sell_name"></el-option>
              <el-option label="联系电话" value="contacts_phone"></el-option>
            </el-select>
          </el-input>
        </el-form-item>
        <el-form-item class="col-auto">
          <el-button native-type="submit" @click.stop.prevent="advancedSearchEvent" size="small"
            type="primary">搜索</el-button>
          <el-button @click="handleEdit" size="small" type="primary">新建申请</el-button>
        </el-form-item>
      </template>
      <template v-if="activeName == '1'" slot="table-columns">
        <el-table-column label="客户编号" show-overflow-tooltip prop="no" width="150" fixed="left" />
        <el-table-column label="客户名称" show-overflow-tooltip prop="name" width="120" fixed="left" />
        <el-table-column label="联系人名称" show-overflow-tooltip prop="contacts_person" width="130" fixed="left" />
        <el-table-column label="联系电话" show-overflow-tooltip prop="contacts_phone" width="120" />
        <el-table-column label="所属销售人员" show-overflow-tooltip prop="sell_name" width="130" />
        <el-table-column label="账期类型" show-overflow-tooltip prop="account_period" width="80">
          <template slot-scope="{row}">{{ row.account_period ? '有账期' : '无账期' }}</template>
        </el-table-column>
        <el-table-column label="创建时间" show-overflow-tooltip width="160">
          <template slot-scope="{row}">{{ row.create_time | unixToDate }}</template>
        </el-table-column>
        <el-table-column label="更新时间" show-overflow-tooltip width="160">
          <template slot-scope="{row}">{{ row.update_time | unixToDate }}</template>
        </el-table-column>
        <el-table-column label="状态" show-overflow-tooltip width="80">
          <template slot-scope="{row}">{{ row.status ? '启用中' : '已禁用' }}</template>
        </el-table-column>
        <el-table-column label="备注信息" show-overflow-tooltip prop="remark" />
        <el-table-column label="操作" width="120" fixed="right">
          <template slot-scope="{row}">
            <el-dropdown @command="exportbatchOps" size="small" style="margin-left: 10px;">
              <el-button type="primary" size="small">
                操作 <i class="el-icon-arrow-down el-icon--right ml-2"></i>
              </el-button>
              <el-dropdown-menu slot="dropdown">
                <el-dropdown-item :command="{ flag: 'edit', row }">
                  编辑
                </el-dropdown-item>
                <el-dropdown-item :command="{ flag: 'open', row }" v-if="row.status == 0">
                  启用
                </el-dropdown-item>
                <el-dropdown-item :command="{ flag: 'close', row }" v-else>
                  禁用
                </el-dropdown-item>
                <el-dropdown-item :command="{ flag: 'remark', row }">
                  备注
                </el-dropdown-item>
                <el-dropdown-item :command="{ flag: 'message', row }">
                  账户信息
                </el-dropdown-item>
                <el-dropdown-item :command="{ flag: 'customized', row }">
                  卡券折扣定制
                </el-dropdown-item>
              </el-dropdown-menu>
            </el-dropdown>
          </template>
        </el-table-column>
      </template>
      <template v-else slot="table-columns">
        <el-table-column label="客户编号" key="customer_no2" show-overflow-tooltip prop="customer_no" fixed="left" />
        <el-table-column label="客户名称" key="customer_name2" show-overflow-tooltip prop="customer_name" fixed="left" />
        <el-table-column label="所属销售人员" key="sell_name2" show-overflow-tooltip prop="sell_name" min-width="130"
          fixed="left" />
        <el-table-column label="申请人" key="create_user_name2" show-overflow-tooltip prop="create_user_name" />
        <el-table-column label="申请时间" key="create_time" width="160">
          <template slot-scope="{row}">{{ row.create_time | unixToDate }}</template>
        </el-table-column>
        <el-table-column label="审核状态" key="apply_status" show-overflow-tooltip prop="apply_status">
          <template slot-scope="{row}">
            <span> {{ row.apply_status | applyStatusFilter }}</span>
            <div v-if="row.apply_status === 4" style="color: red; cursor: pointer" @click="showUnderReason(row)">
              (拒绝原因)
            </div>
          </template>
        </el-table-column>
        <el-table-column label="操作" width="120" fixed="right">
          <template slot-scope="{row}">
            <el-button @click="showView(row)" type="text">申请内容查看</el-button>
          </template>
        </el-table-column>
      </template>
      <el-pagination v-if="activeName == '1'" slot="pagination" @size-change="handleSizeChange"
        @current-change="handleCurrentChange" :current-page="tableData.page_no" :page-sizes="MixinPageSizes" background
        :layout="MixinTableLayout" :page-size="tableData.page_size" :total="tableData.data_total" />
      <el-pagination v-else slot="pagination" @size-change="handleSizeChange" @current-change="handleCurrentChange"
        :current-page="tableDataTwo.page_no" :page-sizes="MixinPageSizes" background :layout="MixinTableLayout"
        :page-size="tableDataTwo.page_size" :total="tableDataTwo.data_total" />
    </en-table-layout>
    <!-- 新建申请 -->
    <el-dialog-x-dialog title="新建申请" :close-on-click-modal="false" :close-on-press-escape="false" append-to-body
      width="550px" :visible.sync="editDialog">
      <div style="padding:10px">
        <el-form :model="applicationForm" ref="applicationForm" :rules="applicationFormRule" label-width="130px"
          size="small">
          <el-form-item label="客户名称" prop="customer_name">
            <el-select style="width: 200px" v-model.trim="applicationForm.customer_no" filterable size="small"
              placeholder="请选择客户" clearable @change="getCustomerDetail(applicationForm.customer_no)">
              <el-option v-for="(item, index) in customerOptions" :key="index" :label="item.name" :value="item.no" />
            </el-select>
          </el-form-item>
          <el-form-item label="账期类型" prop="account_period">
            有账期
          </el-form-item>
          <el-form-item label="账期信用额度" prop="account_period_credit_limit">
            <el-input style="width:200px" type="text" maxlength="100"
              v-model.trim="applicationForm.account_period_credit_limit">
              <template slot="append">元</template>
            </el-input>
          </el-form-item>
          <el-form-item label="账期结算方式" prop="account_period_settlement_method">
            <el-radio-group v-model="applicationForm.account_period_settlement_method">
              <el-radio :label="1">按天</el-radio>
              <el-radio :label="2">按月</el-radio>
            </el-radio-group>
          </el-form-item>
          <el-form-item label="结算周期" prop="account_period_settlement_cycle">
            <el-input style="width:200px" type="text" maxlength="100"
              v-model.trim="applicationForm.account_period_settlement_cycle">
              <template slot="append">{{ applicationForm.account_period_settlement_method == 1 ? '天' : '月' }}</template>
            </el-input>
          </el-form-item>
          <el-form-item label="账期可延期天数" prop="account_period_extended_day">
            <el-input style="width:200px" type="text" maxlength="100"
              v-model.trim="applicationForm.account_period_extended_day">
              <template slot="append">天</template>
            </el-input>
          </el-form-item>
          <el-form-item label="备注信息" prop="remark">
            <el-input style="width:300px" type="textarea" :autosize="{ minRows: 2, maxRows: 5 }"
              placeholder="请输入不超100字的备注信息" maxlength="100" v-model.trim="applicationForm.remark"></el-input>
          </el-form-item>
          <el-form-item style="text-align:right">
            <el-button @click="editDialog = false">取 消</el-button>
            <el-button type="primary" :loading="diolaging" @click="handleConfirm">确 定</el-button>
          </el-form-item>
        </el-form>
      </div>
    </el-dialog-x-dialog>
    <!-- 添加客户备注信息 -->
    <el-dialog-x-dialog title="备注信息" :close-on-click-modal="false" :close-on-press-escape="false" append-to-body
      width="500px" :visible.sync="remarkVisible">
      <div style="padding:10px">
        <el-form :model="remarkForm" label-width="70px" size="small">
          <el-form-item label="备注信息" prop="remarks">
            <el-input style="width:350px" type="textarea" :autosize="{ minRows: 2, maxRows: 5 }"
              placeholder="请输入不超100字的备注信息" maxlength="100" v-model.trim="remarkForm.remarks"></el-input>
          </el-form-item>
          <el-form-item style="text-align:right">
            <el-button @click="remarkVisible = false">取 消</el-button>
            <el-button type="primary" @click="remarkConfirm">确 定</el-button>
          </el-form-item>
        </el-form>
      </div>
    </el-dialog-x-dialog>
    <!-- 申请内容查看-->
    <el-dialog-x-dialog title="查看" :close-on-click-modal="false" :close-on-press-escape="false" append-to-body
      width="500px" :visible.sync="contentVisible">
      <div style="padding:10px">
        <el-form :model="contentInfo" label-width="110px" size="small">
          <h3 style="color:#000">基础信息:</h3>
          <el-form-item style="margin-bottom:0" label="客户名称">{{ contentInfo.customer_name }}</el-form-item>
          <el-form-item style="margin-bottom:0" label="申请时间">{{ contentInfo.create_time | unixToDate }}</el-form-item>
          <el-form-item style="margin-bottom:0" :label="contentInfo.apply_type == 2 ? '用户名' : '申请人'">{{
      contentInfo.create_user_name }}</el-form-item>
          <el-form-item style="margin-bottom:0" label="申请内容">{{ contentInfo.apply_type == 2 ? '有账期信息变更' : '无账期变更为有账期'
            }}</el-form-item>
          <h3 style="color:#000">申请账期信息:</h3>
          <el-form-item style="margin-bottom:0" label="账期类型">{{ contentInfo.account_period ? '有账期' : '无账期'
            }}</el-form-item>
          <el-form-item style="margin-bottom:0" label="账期信息额度">{{ contentInfo.account_period_credit_limit
            }}元</el-form-item>
          <el-form-item style="margin-bottom:0" label="账期结算方式">{{ contentInfo.account_period_settlement_method == 1 ?
      '按天' :
      '按月'
            }}</el-form-item>
          <el-form-item style="margin-bottom:0" label="结算周期">
            {{ contentInfo.account_period_settlement_cycle }}
            <span>{{ contentInfo.account_period_settlement_method == 1 ? '天' : '月' }}</span>
          </el-form-item>
          <el-form-item style="margin-bottom:0" label="账期可延期天数">{{ contentInfo.account_period_extended_day
            }}天</el-form-item>
          <el-form-item style="margin-bottom:0" label="备注信息">{{ contentInfo.remark }}</el-form-item>
        </el-form>
      </div>
    </el-dialog-x-dialog>
    <!-- 账户信息 -->
    <el-dialog-x-dialog title="账户信息" :close-on-click-modal="false" :close-on-press-escape="false" append-to-body
      width="650px" :visible.sync="messageVisible">
      <div style="padding:10px" class="messageTable">
        <el-form class="orderDetail" :model="ordinaryInfo" label-width="140px">
          <el-tabs v-model="activeMessage" type="card" v-if="ordinaryInfo.customer_customized_project_dtolist != ''">
            <el-tab-pane label="普通开卡账户" name="1">

            </el-tab-pane>
            <el-tab-pane label="专项账户" name="2">

            </el-tab-pane>
          </el-tabs>
          <div v-if="activeMessage == '1'">
            <el-form-item label="账期类型">{{ ordinaryInfo.account_period ? '有账期' : '无账期' }}</el-form-item>
            <div v-if="ordinaryInfo.account_period">
              <el-form-item label="账期额度剩余">{{ ordinaryInfo.account_period_credit_limit_left }}元</el-form-item>
              <el-form-item label="实收款">{{ ordinaryInfo.left_deposit }}元</el-form-item>
              <el-form-item label="本次账期应收款">{{ ordinaryInfo.current_account_period_receivable }}元</el-form-item>
              <el-form-item label="上次账期应收款">{{ ordinaryInfo.pre_account_period_receivable }}元</el-form-item>
            </div>
            <el-form-item v-else label="账户余额">{{ ordinaryInfo.left_deposit }}元</el-form-item>
          </div>
          <div v-if="activeMessage == '2'">
            <el-form-item label="商城名称">
              <el-select v-model="selectShopId" placeholder="请选择" @change="changeShop">
                <el-option v-for="shops in ordinaryInfo.customer_customized_project_dtolist" :key="shops.shop_id"
                  :label="shops.shop_name" :value="shops.shop_id">
                </el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="项目总金额">{{ special.total_amount || 0 }}元</el-form-item>
            <el-form-item label="剩余未结算">{{ special.residue_amount || 0 }}元</el-form-item>
            <el-form-item label="项目结算次数">{{ special.settlement_times || 0 }}次</el-form-item>
            <el-form-item label="项目结算日期及金额"></el-form-item>
            <el-table style="width: 620px" border height="300px" stripe
              :data="special.customer_customized_project_settlement_dtolist">
              <el-table-column prop="date" label="结算日期" width="200">
                <template slot-scope="{row}">{{ row.settlement_date | unixToDate }}</template>
              </el-table-column>
              <el-table-column prop="settlement_amount" label="预计结算金额" min-width="150" />
              <el-table-column prop="real_settlement_amount" label="实际结算金额" min-width="150" />
              <el-table-column prop="settlemented" label="是否结算" width="100">
                <template slot-scope="{row}">{{ row.settlemented == 1 ? '已结算' : '未结算' }}</template>
              </el-table-column>
            </el-table>
          </div>
        </el-form>
      </div>
    </el-dialog-x-dialog>
    <el-dialog-x-dialog title="拒绝原因" :visible.sync="isShowUnderReason" width="17%">
      <div align="center" style="padding: 10px;">{{ under_reason ? under_reason : "无" }}</div>
    </el-dialog-x-dialog>
    <settingcoupon ref="settingCoupon"></settingcoupon>
  </div>
</template>
<script>
import EnTableLayout from "@/../ui-components/TableLayout/src/main";
import * as API_settingCustomer from '@/api/settingCustomer';
import { RegExp, Foundation } from '@/../ui-utils';
import { handleDownload } from "@/utils";
import settingcoupon from "./components/settingcoupon";
export default {
  name: "customerProfile",
  components: { EnTableLayout, settingcoupon },
  filters: {
    applyStatusFilter (status) {
      console.log(typeof status);
      const statusMap = {
        0: '待部门初审',
        1: '待财务复审',
        3: '审核完成',
        4: '审核拒绝'
      }
      return statusMap[status]
    },
  },
  data () {
    return {
      diolaging: false,//加载状态 防止重复提交
      contentVisible: false,//申请内容查看
      contentInfo: {},//申请内容
      importLoading: false,
      //客户账户信息
      selectShopId: '',//选中的商城
      special: '',//商城详细信息
      messageVisible: false,
      activeMessage: '1',
      ordinaryInfo: {},
      // 客户备注信息
      remarkForm: {
        remarks: ''
      },
      remarkVisible: false,
      customerOptions: [],//客户申请账期列表
      activeName: '1',
      //申请账期表单
      editDialog: false,
      applicationForm: {
        customer_name: '',//客户名称
        customer_no: '',//客户编号
        account_period: 1,//账期类型
        account_period_credit_limit: '',//账期信用额度
        account_period_settlement_method: 1,//账期结算方式
        account_period_settlement_cycle: '',//账期结算周期
        account_period_extended_day: '',//账期可延期天数
        remark: '',//备注
      },
      //账期申请条件效验
      applicationFormRule: {
        customer_name: [
          { required: true, message: '请选择客户', trigger: 'change' }
        ],
        account_period_credit_limit: [
          { required: true, message: '请填写账期额度', trigger: 'blur' },
          {
            validator: (rule, value, callback) => {
              const money = /^\d+(\.\d{1,2})?$/
              if (!RegExp.money.test(value) || !money.test(value) || Number(value) <= 0) {
                callback(new Error("请输入正确的金额"));
              }
              else if (value > 1000000) {
                callback(new Error('输入最大值不超1000000'))
              } else {
                callback()
              }
            }, trigger: 'blur'
          }
        ],
        account_period_settlement_cycle: [
          { required: true, message: '请填写结算周期', trigger: 'blur' },
          {
            validator: (rule, value, callback) => {
              if (!/^[1-9]\d*$/.test(value)) {
                callback(new Error(`${this.applicationForm.account_period_settlement_method == 1 ? '请输入3~1000的正整数' : '请输入1~100的正整数'}`));
              } else if (this.applicationForm.account_period_settlement_method == 1 && (value > 1000 || value < 3)) {
                callback(new Error("请输入3~1000的正整数"));
              } else if (this.applicationForm.account_period_settlement_method == 2 && value > 100) {
                callback(new Error("结算周期不能大于100"));
              } else {
                callback()
              }
            }, trigger: 'blur'
          }
        ],
        account_period_extended_day: [
          {
            validator: (rule, value, callback) => {
              if (!value) {
                callback()
              }
              else if (!/^\d+$/.test(value)) {
                callback(new Error("请输入正确的延期天数"));
              } else if (value > 20) {
                callback(new Error("延期天数不能大于20天"));
              } else if (this.applicationForm.account_period_settlement_cycle == '') {
                callback(new Error("请先设置结算周期"));
              } else if (this.applicationForm.account_period_settlement_method == 1 && value >= Number(this.applicationForm.account_period_settlement_cycle)) {
                callback(new Error("延期天数需小于结算周期天数"));
              } else {
                callback()
              }
            }, trigger: 'blur'
          }
        ]
      },
      order_time_range: [],
      params: {
        keyWords: '',
        page_size: 20,
        page_no: 1,
        status: '',
        apply_status: '',
        account_period: ''
      },
      search_type: 'name',
      tableData: {
        data: []
      },
      tableDataTwo: {
        data: []
      },
      isShowUnderReason: false,//拒绝原因弹窗
      under_reason: '',//拒绝内容
    }
  },
  created () {
    this.getList();
    this.getAllCustomerListByEnterpriseId();
  },
  activated () {
    this.getList();
    this.$store.commit("DEL_VISITED_VIEWS", this.$route);
  },
  methods: {
    /** 显示拒绝原因 */
    showUnderReason (row) {
      this.isShowUnderReason = true;
      this.under_reason = row.apply_audit_reject_remark;
    },
    /**查看申请内容 */
    showView (row) {
      this.contentVisible = true;
      API_settingCustomer.getCustomerCapaDetail(row.id).then(res => {
        this.contentInfo = res;
      })
    },
    /**商城选择事件 */
    changeShop () {
      this.special = this.ordinaryInfo.customer_customized_project_dtolist.find(res => this.selectShopId == res.shop_id)
    },
    //查询客户列表下拉框
    getAllCustomerListByEnterpriseId () {
      const shop_id = this.$store.getters.shopInfo.shop_id;
      API_settingCustomer.getAllCustomerListByEnterpriseId(shop_id).then(res => {
        this.customerOptions = res.filter(item => item.status == 1)
      })
    },
    /**选择客户change事件 */
    getCustomerDetail (no) {
      this.customerOptions.forEach(res => {
        if (res.no == no) {
          this.applicationForm.customer_id = res.id;//id
          this.applicationForm.customer_name = res.name;//编号
          this.applicationForm.account_period = 1;//账期类型
          this.applicationForm.account_period_credit_limit = res.account_period_credit_limit;//账期信用额度
          this.applicationForm.account_period_settlement_method = res.account_period_settlement_method? res.account_period_settlement_method : 1;//账期结算方式
          this.applicationForm.account_period_settlement_cycle = res.account_period_settlement_cycle;//账期结算周期
          this.applicationForm.account_period_extended_day = res.account_period_extended_day;//账期可延期天数
        }
      })
    },
    //格式化时间控件
    handleDateChange () {
      if (
        this.order_time_range &&
        this.order_time_range.length
      ) {
        if (typeof this.order_time_range[0] === "string") {
          this.params.update_time_start =
            new Date(this.order_time_range[0]).getTime() / 1000;
          this.params.update_time_end =
            new Date(this.order_time_range[1]).getTime() / 1000 +
            86400;
        } else {
          this.params.update_time_start =
            this.order_time_range[0].getTime() / 1000;
          this.params.update_time_end =
            this.order_time_range[1].getTime() / 1000 + 86400;
        }
      } else {
        this.params.update_time_start = "";
        this.params.update_time_end = "";
      }
    },
    handleDateChangeTwo () {
      if (
        this.order_time_range &&
        this.order_time_range.length
      ) {
        if (typeof this.order_time_range[0] === "string") {
          this.params.create_time_start =
            new Date(this.order_time_range[0]).getTime() / 1000;
          this.params.create_time_end =
            new Date(this.order_time_range[1]).getTime() / 1000 +
            86400;
        } else {
          this.params.create_time_start =
            this.order_time_range[0].getTime() / 1000;
          this.params.create_time_end =
            this.order_time_range[1].getTime() / 1000 + 86400;
        }
      } else {
        this.params.create_time_start = "";
        this.params.create_time_end = "";
      }
    },
    handleEdit () {//打开新建申请
      this.getAllCustomerListByEnterpriseId();
      this.editDialog = true;
      this.applicationForm = {
        customer_name: '',//客户名称
        account_period: 1,//账期类型
        account_period_credit_limit: '',//账期信用额度
        account_period_settlement_method: 1,//账期结算方式
        account_period_settlement_cycle: '',//账期结算周期
        account_period_extended_day: '',//账期可延期天数
        remark: '',//备注
      };
    },
    /**切换页签 */
    handleClick () {
      this.order_time_range = [];
      this.params = {
        keyWords: '',
        page_size: 20,
        page_no: 1,
        status: '',
        apply_status: '',
        account_period: ''
      }

      if (this.activeName == '2') {
        this.search_type = 'customer_name';
      } else {
        this.search_type = 'name';
      }
      this.getList(1);
    },
    /**添加、编辑客户 */
    addCustom (row) {
      this.$store.dispatch("addVisitedViews", this.$route);
      if (row) {  //编辑
        this.$router.push({
          name: "editCustomerProfile",
          params: {
            id: row.id,
          },
        });
      } else {//添加
        this.$router.push({
          name: "editCustomerProfile",
        });
      }
    },
    //禁用|启用
    handleDisable (row) {
      console.log(row.id);
      const statusName = row.status === 0 ? '启用' : '禁用';
      this.$confirm(`确定要${statusName}当前的客户吗?`, '提示', {
        type: 'warning',
      }).then(() => {
        const status = row.status === 0 ? 1 : 0;
        API_settingCustomer.enableCustomer(row.id, status).then((res) => {
          this.getList();
          this.$message.success(`${statusName}成功`);
        });
      });
    },
    /**客户备注信息 */
    remarkConfirm () {
      API_settingCustomer.addCustomerRemark(this.remarkForm).then((res) => {
        this.getList();
        this.remarkForm = {};
        this.remarkVisible = false;
        this.$message.success(`添加成功`);
      });
    },
    /**查询客户账户信息 */
    getCustomerMessage (row) {
      API_settingCustomer.getCustomerAll(row.id).then((res) => {
        this.messageVisible = true;
        this.activeMessage = '1';
        this.ordinaryInfo = res;
        if (this.ordinaryInfo.customer_customized_project_dtolist) {

          this.selectShopId = this.ordinaryInfo.customer_customized_project_dtolist[0].shop_id;
          this.special = this.ordinaryInfo.customer_customized_project_dtolist[0];
        }
      });
    },

    /**导出全部 */
    submitImport () {
      this.importLoading = true;
      let tHeaders = [
        "客户编号",
        "客户名称",
        "联系人名称",
        "联系电话",
        "所属销售人员",
        "账期类型",
        "创建时间",
        "更新时间",
        "状态",
        "备注信息"
      ];
      let filterVals = [
        "no",
        "name",
        "contacts_person",
        "contacts_phone",
        "sell_name",
        "account_period",
        "create_time",
        "update_time",
        "status",
        "remark"
      ];
      let params = { ...this.params };
      API_settingCustomer.getCustomerExport(params).then((res) => {
        res.forEach(item => {
          item.account_period = item.account_period ? '有账期' : '无账期';
          item.status = item.status ? '启用中' : '已禁用';
          item.create_time = Foundation.unixToDate(item.create_time)
          item.update_time = Foundation.unixToDate(item.update_time)
        })
        handleDownload(res, tHeaders, filterVals, "客户档案");
        this.importLoading = false;
      });
    },
    exportbatchOps ({ flag, row }) {//操作按钮
      switch (flag) {
        case 'edit':
          return this.addCustom(row);
        case 'open':
          return this.handleDisable(row);
        case 'close':
          return this.handleDisable(row);
        case 'remark':
          return this.remarkVisible = true, this.remarkForm.id = row.id;
        case 'message':
          return this.getCustomerMessage(row);
        case 'customized':
          return this.$refs.settingCoupon.show(row);
      }
    },
    /**搜索 */
    advancedSearchEvent () {

      this.getList(1);
    },
    /**查询列表数据 */
    getList (page_no) {
      let params = {
        ...this.params
      }
      page_no && (params.page_no = 1);
      params[this.search_type] = params.keyWords;
      delete params.keyWords;
      let apiUrl = this.activeName == 1 ? 'getCustomerList' : 'getCustomerCapaList';
      API_settingCustomer[apiUrl](params).then((res) => {
        this.activeName == 1 ? (this.tableData = res) : (this.tableDataTwo = res)
      });
    },
    handleSizeChange (val) {
      this.params.page_size = val;
      this.getList();
    },
    handleCurrentChange (val) {
      this.params.page_no = val;
      this.getList();
    },
    handleConfirm () {
      // 校验表单
      this.$refs.applicationForm.validate((validate) => {
        if (validate) {
          this.diolaging = true;
          API_settingCustomer.addCustomerCapa(this.applicationForm).then((res) => {
            this.$message.success("申请成功");
            this.editDialog = false;
            this.diolaging = false;
            this.getList(1);
          }).catch(() => { this.diolaging = false; });
        }
      });
    }
  }
}

</script>
<style lang="scss" scoped>
.headerStep {
  display: flex;

  p {
    margin: 0
  }

  .stepImage {
    display: flex;
    flex-direction: column;
    width: 200px;
    justify-content: center;
    align-items: center;

    img {
      width: 70px;
    }

    span {
      margin-top: 15px;
    }
  }

  .stepLine {
    display: flex;
    align-items: center;
    margin: 0 10px 39px;

    .line {
      display: inline-block;
      width: 80px;
      height: 4px;
      background: #333;
    }

    .arrow-right {
      display: inline-block;
      width: 0;
      height: 0;
      border-top: 10px solid transparent;
      border-bottom: 12px solid transparent;
      border-left: 10px solid #333;
    }
  }
}

.el-radio {
  margin-bottom: 0;
}

.messageTable {
  .orderDetail {
    .el-form-item {
      margin-bottom: 0;
    }
  }

  /deep/ .el-table {
    .el-table__fixed,
    .el-table__fixed-right {
      height: auto !important;
      bottom: 8px;
    }

    th {
      background: #E6ECF7;
      color: #333;
      font-weight: 600;
      line-height: 1;
      padding: 8px 0;
    }

    .cell {
      line-height: 1.2;
    }

    .el-table--border th {
      border-right: 1px solid rgba(220, 223, 230, 1);
    }

    .el-table--border td {
      border-right: 1px solid rgba(220, 223, 230, 1);
    }
  }
}
</style>