<template>
  <div class="settingcoupon">
    <el-dialog-x-dialog title="卡券折扣定制" :visible.sync="settingDialog" width="75%">
      <el-form :model="batchForm" :rules="batchFormRule" ref="batchForm">
        <en-table-layout class="enTableLayout" :tableData="batchForm.customized_discount_list">
          <template slot="toolbar">
            <el-form-item>
              <el-select style="width: 140px" @change="handleCommand" v-model="params.discount_template_id" size="small"
                placeholder="折扣模板选择">
                <el-option v-for="item in cardList" :key="item.id" :label="item.name" :value="item.id" />
              </el-select>
            </el-form-item>
          </template>

          <template slot="table-columns">
            <el-table-column prop="card_name" label="卡券名称" show-overflow-tooltip />
            <el-table-column prop="card_value" label="卡券面值/价位" width="120" />
            <el-table-column prop="shop_name" label="所属商城" show-overflow-tooltip />
            <el-table-column prop="card_type" label="卡券应用类型" width="120">
              <template slot-scope="scope">
                {{ scope.row.card_type | cardTypeFilter }}
              </template>
            </el-table-column>
            <el-table-column prop="discount_rate" label="折扣率(标品)" width="110">
              <template slot-scope="scope">
                {{ scope.row.discount_rate + "%" }}
              </template>
            </el-table-column>
            <el-table-column prop="customized_discount_rate" show-overflow-tooltip label="模板折扣率" width="110">
              <template slot-scope="scope">
                {{ scope.row.customized_discount_rate + "%" }}
              </template>
            </el-table-column>
            <el-table-column prop="apply_discount_rate" label="定制折扣率" width="120">
              <template slot-scope="scope">
                <el-form-item :prop="`customized_discount_list.${scope.$index}.apply_discount_rate`"
                  :rules="batchFormRule.apply_discount_rate">
                  <el-input :disabled="scope.row.apply_status === 0"
                    v-model.trim="scope.row.apply_discount_rate"></el-input>
                </el-form-item>

              </template>
            </el-table-column>
            <el-table-column prop="apply_status" show-overflow-tooltip label="审核状态" width="90">
              <template slot-scope="{row}">
                <span>{{ row.apply_status | apply_status_filter }}</span>
                <div v-if="row.apply_status === 1" style="color: red; cursor: pointer" @click="showUnderReason(row)">
                  (拒绝原因)
                </div>
              </template>
            </el-table-column>
            <el-table-column label="操作" width="90">
              <template slot-scope="scope">
                <el-button :disabled="scope.row.apply_status == 0" size="mini" type="danger"
                  @click="handleDel(scope.row)">删除</el-button>
              </template>
            </el-table-column>
          </template>
        </en-table-layout>
      </el-form>
      <div style="text-align:right;padding: 10px 0;">
        <el-button @click="settingDialog = false" size="small">取 消</el-button>
        <el-button type="primary" size="small" @click="submitForm">确 定</el-button>
      </div>
    </el-dialog-x-dialog>
    <el-dialog-x-dialog title="拒绝原因" :visible.sync="isShowUnderReason" width="17%">
      <div align="center" style="padding: 10px;">{{ under_reason ? under_reason : "无" }}</div>
    </el-dialog-x-dialog>
    <el-dialog-x-dialog title="提示" :visible.sync="isShowWarning" width="25%">
      <div align="center" style="padding: 10px;">要设置的定制折扣中存在低于模板折扣率的数据，系统将会提交相关部门进行审核，是否确认修改并提交审核？</div>
      <div style="text-align:right;padding: 10px 0;">
        <el-button @click="isShowWarning = false" size="small">取 消</el-button>
        <el-button type="primary" size="small" @click="transferConfirm">确 定</el-button>
      </div>
    </el-dialog-x-dialog>
  </div>
</template>

<script>
import EnTableLayout from '@/../ui-components/TableLayout/src/main';
import XDialog from "@/components/x-dialog/x-dialog";
import { getShopExtAuth } from '@/api/order';
import { getShopLv } from '@/api/shop';
import { getCustomizedDiscountCardsList, batchAddCustomizedCards, getDiscountRateList, getDiscountCardsList } from '@/api/settingCustomer';
export default {
  name: 'settingcoupon',
  components: {
    EnTableLayout
  },
  filters: {
    apply_status_filter (apply_status) {
      const statusMap = {
        0: '审核中',
        1: '已拒绝',
        2: '已完成'
      }
      return statusMap[apply_status]
    },
    cardTypeFilter (card_type) {
      switch (card_type) {
        case 3:
          return '现金储值卡'
        case 0:
          return '双选储值卡'
        case 5:
          return 'AI智能套餐卡'
        case 6:
          return '计次卡'
        default:
          return '宅配卡'
      }
    }
  },
  data () {
    return {
      //选中得勾选项
      selectCarmiTable: [],
      cardList: [],
      //来源应用数组
      mallTypeList: [
        { name: '全部', value: '' },
        { name: '现金储值卡', value: 3 }
      ],
      key_word: 'card_name',
      params: {
        keyWord: '',
        apply_status: "",
        card_type: '',
        page_size: 99999,
        page_no: 1,
        discount_template_id: ''

      },
      settingDialog: false,
      batchForm: {
        customer_id: '',
        customized_discount_list: [],
        discount_template_id: '',
      },
      batchFormRule: {
        apply_discount_rate: [
          { required: true, message: '请填写定制折扣率', trigger: 'blur' },
          {
            validator: async (rule, value, callback) => {
              const reg = /^(0|[1-9]\d*)(\.\d{1,2})?$/
              if (isNaN(value) || value < 0 || value > 100 || !reg.test(value)) {
                callback(new Error('仅允许输入0~100，且最多到小数点后两位的正数'))
              } else {
                callback()
              }
            }
          }
        ]
      },
      isShowUnderReason: false,//拒绝原因弹窗
      under_reason: '',
      isShowWarning: false,//提示弹窗
    }
  },
  async mounted () {
    // await this.getTableList()
    //判断大平台开关
    const doubledata = await getShopExtAuth();
    const aicard20 = await getShopLv();
    if (doubledata.double_choice_mall_open == "OPEN") this.mallTypeList.push({ name: '双选储值卡', value: 0 })
    if (aicard20.data.has_intelligent_card20 == 1) {
      this.mallTypeList.push({ name: 'AI智能套餐卡', value: 5 })
    }
    if (doubledata.counting_card_open == "OPEN") {
      this.mallTypeList.push({ name: '计次卡', value: 6 })
      this.mallTypeList.push({ name: '宅配卡', value: 7 })
    }
    const { data } = await getDiscountRateList({ page_size: 0, page_no: 0 })
    this.cardList = data;
  },
  methods: {
    /** 显示拒绝原因 */
    showUnderReason (row) {
      this.isShowUnderReason = true;
      this.under_reason = row.refuse_reason;
    },
    handleCommand (command) {
      //查询模板下卡券
      this.gettemplateCard();
      this.batchForm.discount_template_id = this.params.discount_template_id;
    },
    async show (row) {
      this.parentId = row.id;
      this.batchForm.customer_id = this.parentId;
      this.params = {
        keyWord: '',
        apply_status: "",
        card_type: '',
        page_size: 99999,
        page_no: 1,
        customer_id: row.id,
        discount_template_id: ''
      },
        this.settingDialog = true;
      await this.getTableList()
      if (this.batchForm.customized_discount_list.length > 0) {
        this.params = {
          ...this.params,
          discount_template_id: this.batchForm.customized_discount_list[0].template_id
        }
      }
    },
    /**查询模板下卡券 */
    gettemplateCard () {
      const params = {
        page_size: 0,
        page_no: 0,
        discount_template_id: this.params.discount_template_id
      }
      getDiscountCardsList(params).then((res) => {
        //得到模板数据前，清空数据
        this.batchForm.customized_discount_list=[]
        res.data.forEach(item => {
          item.customized_discount_rate = item.template_discount_rate;//模板折扣率
          item.apply_discount_rate = item.template_discount_rate;//定制折扣率
          item.apply_status = 2;//审核状态
          item.customer_id = this.parentId;//客户id
        })
        let uniqueArray = res.data.reduce((acc, current) => {
            let x = acc.find(item => item.card_id === current.card_id);
            if (!x) {
                acc.push(current);
            }
            return acc;
        }, []);
        //去重后重新赋值模板列表
        this.batchForm.customized_discount_list = uniqueArray;
      });
    },
    reset () {

    },
    /**批量添加折扣模板卡券 */
    submitForm () {
      this.$refs.batchForm.validate(valid => {
        if (valid) {
          const valid = this.batchForm.customized_discount_list == '' || this.batchForm.customized_discount_list.some(res => res.template_discount_rate !== '')
          if (this.batchForm.customized_discount_list.some(item => item.apply_status == 0)) {
            this.$message.error('存在折扣审批中的卡券数据，暂不允许保存提交')
          } else {
            if (valid) {
              if (this.batchForm.customized_discount_list.some(item => item.audited_discount_rate != item.apply_discount_rate && item.apply_discount_rate < item.customized_discount_rate)) {
                this.isShowWarning = true;
              } else {
                this.isShowWarning = false;
                batchAddCustomizedCards(this.batchForm).then(res => {
                  this.$message.success('保存成功')
                  this.settingDialog = false;
                })
              }
            } else {
              this.$message.error('请填写定制折扣率')
            }
          }
        }
      })
    },
    transferConfirm () {
      this.isShowWarning = false;
      batchAddCustomizedCards(this.batchForm).then(res => {
        this.$message.success('保存成功')
        this.settingDialog = false;
      })
    },
    //删除
    handleDel (row) {
      this.batchForm.customized_discount_list = this.batchForm.customized_discount_list.filter(res => res.id !== row.id)
    },
    /**查询卡券列表 */
    async getTableList (page_no) {
      let params = { ...this.params };
      page_no && (params.page_no = 1);
      params[this.key_word] = params.keyWord;
      delete params.keyWord;
      params.discount_template_id ? '' : delete params.discount_template_id;
      const res = await getCustomizedDiscountCardsList(params)
      this.batchForm.customized_discount_list = res.data;
      this.batchForm.discount_template_id = res.data[0].template_id;

    },
    handleSizeChange (val) {
      this.params.page_size = val;
      this.getTableList();
    },
    handleCurrentChange (val) {
      this.params.page_no = val;
      this.getTableList();
    }
  }
};
</script>
<style lang="scss" scoped>
/deep/ .enTableLayout {
  .el-table {
    height: 400px !important;
  }

  .el-table__body-wrapper {
    height: 370px !important;
  }

  .el-form-item {
    margin-bottom: 0;
  }

  .el-form-item__error {
    position: relative;
  }
}
</style>